<dropdown
  [attr.data-id]="field.key"
  [value]="formControl.value"
  [placeholder]="field.props.placeholder"
  [touched]="formControl.touched"
  [disabled]="formControl.disabled"
  [errors]="formControl.errors"
  [options]="field.props.options"
  (selectOption)="formControl.setValue($event)"
  (markAsTouched)="formControl.markAsTouched()"
></dropdown>
