import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { setMaxHeight } from '@app/utils/utils';

declare global {
  interface Window {
    track: (params: unknown) => void;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  ngOnInit() {
    setMaxHeight();
    window.addEventListener('resize', setMaxHeight);
  }
}
