import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { combineLatest, map, Observable } from 'rxjs';

import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import { State, StateService } from '@app/services/state/state.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { PortalFeatures, PortalLeadNode, PortalOrderNode } from '@app/services/api/api.types';
import { Steps } from '@app/components/stepper/stepper.component';
import { beforeUnloadHandler } from '@app/utils/utils';

@Component({
  selector: 'cancel-for-any-reason-page',
  templateUrl: './cancel-for-any-reason-page.component.html',
  styleUrls: ['./cancel-for-any-reason-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelForAnyReasonPageComponent implements OnInit, OnDestroy {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;
  state: State;

  cancelForAnyReason: number;

  withPayAndConfirm: boolean;

  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<unknown>;

  constructor(
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private stateService: StateService,
    private popupsService: PopupsService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalLead) {
          this.withPayAndConfirm = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_cc
          );
        }

        if (portalOrder) {
          this.portalOrder = portalOrder;
        }

        this.state = this.stateService.getState();

        if (this.state.cancelForAnyReason) {
          this.cancelForAnyReason = this.state.cancelForAnyReason;
        }

        return portalLead;
      })
    );
  }

  tryBack() {
    if (this.cancelForAnyReason) {
      this.popupsService.showModal(this.confirmTemplate);
      return;
    }

    this.back();
  }

  back() {
    this.location.back();
  }

  selectCancelForAnyReason(cancelForAnyReason: number) {
    this.cancelForAnyReason = cancelForAnyReason;
    this.stateService.patchState({ cancelForAnyReason });

    window.addEventListener('beforeunload', beforeUnloadHandler); // todo how many times will the listener add?
  }

  submit() {
    const path = [`${this.state.portalLink}`, `${this.portalOrder.portalLink}`, `gratitude`];

    void this.router.navigate(path);
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}
