<input
  [formControl]="formControl"
  [formlyAttributes]="field"
  [ngClass]="{ 'error': formControl.touched && formControl.errors }"
  [attr.data-id]="field.key"
  class="input"
  autocomplete="off"
  ccNumber
/>

<ng-container [ngSwitch]="formControl.value | ccType">
  <img *ngSwitchCase="'mastercard'" src="assets/images/mc.svg" alt="mc" class="cc-type" />
  <img *ngSwitchCase="'visa'" src="assets/images/visa.svg" alt="visa" class="cc-type" />
  <img *ngSwitchCase="'amex'" src="assets/images/amex.svg" alt="amex" class="cc-type" />
</ng-container>
