<ng-container *ngIf="status === PortalBookingStatusTypeEnum.InProgress; else checkIconTemplate">
  <img src="assets/icons/time-orange.svg" alt="check" class="mr-2" />
</ng-container>

<ng-template #checkIconTemplate>
  <img src="assets/icons/check.svg" alt="check" class="mr-2" />
</ng-template>

<ng-container [ngSwitch]="status">
  <p *ngSwitchCase="PortalBookingStatusTypeEnum.Booked" data-id="status">
    Booking request has been sent
  </p>
  <p *ngSwitchCase="PortalBookingStatusTypeEnum.InProgress" data-id="status">
    We're working on your booking
  </p>
  <p *ngSwitchCase="PortalBookingStatusTypeEnum.Issued" data-id="status">
    Your booking has been issued
  </p>
</ng-container>
