<div class="d-flex align-items-center justify-content-between p-4 p-md-6 header">
  <h2 class="semi-bold">Itinerary adjusted for price and savings</h2>

  <img
    (click)="hideModal.emit()"
    src="assets/icons/close.svg"
    alt="close"
    class="cursor-pointer d-none d-md-inline"
    data-id="hide"
  />
</div>

<ng-container *ngIf="presentedOption; else loadingTemplate">
  <div class="mt-4 mx-md-6 p-4 block">
    <div class="d-flex align-items-center">
      <img src="assets/icons/error-orange.svg" alt="error" />

      <p class="ml-1 semi-bold text-sun-crete-700">New itinerary, same price!</p>
    </div>

    <p class="mt-1 text-sun-crete-700">
      Looks like the flights you picked aren’t available at that price anymore. No worries though—we
      found another itinerary that keeps the same price. Hope this works for you! Have questions?
      Ask your travel agent now.
    </p>
  </div>

  <div class="d-none d-md-flex">
    <portal-option
      (requestToBook)="requestToBook.emit(presentedOption)"
      [option]="presentedOption"
      [showedDetails]="true"
      class="my-4 mx-md-6 w-100"
      data-id="option"
    ></portal-option>
  </div>

  <option-details-modal
    (requestToBook)="requestToBook.emit(presentedOption)"
    [option]="presentedOption"
    class="d-md-none mt-4"
    data-id="option"
  ></option-details-modal>
</ng-container>

<ng-template #loadingTemplate>
  <div class="d-flex d-md-none flex-column p-4">
    <skeleton [height]="24" class="w-40"></skeleton>
    <skeleton [height]="20" class="mt-2"></skeleton>
    <skeleton [height]="288" class="mt-4"></skeleton>
  </div>

  <div class="d-none d-md-flex flex-column py-4 px-6">
    <skeleton [height]="40" class="w-40"></skeleton>
    <skeleton [height]="20" class="mt-2"></skeleton>
    <skeleton [height]="120" class="mt-8"></skeleton>
  </div>
</ng-template>
