import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFirstName',
})
export class GetFirstNamePipe implements PipeTransform {
  transform(value = '') {
    return value.split(' ')[0] || '';
  }
}
