<card class="d-flex flex-column p-4">
  <ng-container *ngFor="let passenger of passengers; let first = first; let index = index">
    <p [ngClass]="{ ' mt-4': !first }" class="text-jazlyn-700">Traveler {{ index + 1 }}</p>

    <p class="mt-1 text-ellipsis" data-id="pax">
      {{ passenger.firstName }}<ng-container *ngIf="passenger.middleName"
        >&nbsp;{{ passenger.middleName }}</ng-container
      >&nbsp;{{ passenger.lastName }}<dot-separator class="mx-2"></dot-separator>{{
      passenger.birthday | date }}<dot-separator class="mx-2"></dot-separator>{{ passenger.gender |
      label }}<dot-separator class="mx-2"></dot-separator
      ><ng-container *ngIf="passenger.passportCountryId | getCountryById | async as country"
        >{{ country.label }}</ng-container
      >
    </p>
  </ng-container>
</card>
