<div
  class="d-flex flex-column flex-md-row align-items-md-center justify-content-center px-4 py-6 px-md-10 py-md-6 footer"
>
  <div class="d-flex flex-column flex-md-row">
    <div class="d-flex align-items-center">
      <img src="assets/images/asta.svg" alt="asta" width="32" height="32" />

      <div class="d-flex flex-column ml-4 text-jazlyn-700">
        <p>American Society of Travel Agents</p>
        <p>Reg. # 900272552</p>
      </div>
    </div>

    <div class="d-flex align-items-center mt-4 mt-md-0 ml-md-8">
      <img src="assets/images/arc.svg" alt="arc" width="32" height="32" />

      <div class="d-flex flex-column ml-4 text-jazlyn-700">
        <p>Airlines Reporting Corporation</p>
        <p>Reg. # 05615352</p>
      </div>
    </div>

    <div class="d-flex align-items-center mt-4 mt-md-0 ml-md-8">
      <img src="assets/images/cst.svg" alt="cst" width="32" height="32" />

      <div class="d-flex flex-column ml-4 text-jazlyn-700">
        <p>California Seller of Travel</p>
        <p>Lic. # 2116669</p>
      </div>
    </div>
  </div>
</div>
