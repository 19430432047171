<div class="head p-4 px-md-6">
  <h3 class="semi-bold">Hotel stay</h3>
</div>

<div *ngFor="let hotelBooking of hotelBookings" class="p-4 px-md-6 d-flex align-items-center cost">
  <img src="assets/icons/hotel_linear.svg" alt="hotels" width="24" height="24" />

  <div class="d-flex flex-column ml-4">
    <p class="semi-bold" data-id="hotel-name">{{ hotelBooking.name }}</p>

    <div class="d-flex align-items-center flex-wrap mt-1">
      <p data-id="city">{{ hotelBooking.city }}</p>

      <dot-separator class="mx-2"></dot-separator>

      <p data-id="dates">
        {{ hotelBooking.checkInDate | date: 'MMM d' }} – {{ hotelBooking.checkoutDate | date: 'MMM
        d' }}
      </p>

      <dot-separator class="mx-2"></dot-separator>

      <p data-id="guests">
        {{ hotelBooking.guestCount }}
        <ng-container [ngPlural]="hotelBooking.guestCount">
          <ng-template ngPluralCase="=1"> guest</ng-template>
          <ng-template ngPluralCase="other"> guests</ng-template>
        </ng-container>
      </p>
    </div>
  </div>
</div>
