import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PortalOrderNode } from '@app/services/api/api.types';

@Injectable({
  providedIn: 'root',
})
export class PortalOrderService {
  portalOrder$$ = new BehaviorSubject<PortalOrderNode | null>(null);

  get portalOrder$() {
    return this.portalOrder$$.asObservable();
  }

  setPortalOrder(portalOrder: PortalOrderNode | null) {
    return this.portalOrder$$.next(portalOrder);
  }
}
