import { Pipe, PipeTransform } from '@angular/core';
import { CreditCard } from 'angular-cc-library';

@Pipe({
  name: 'ccType',
})
export class CcTypePipe implements PipeTransform {
  transform(value?: string) {
    return value ? CreditCard.cardType(value) : null;
  }
}
