import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() error: boolean;

  @HostBinding('class') get classes() {
    return this.disabled ? ['disabled', 'cursor-default'] : ['cursor-pointer'];
  }
}
