<ng-container *ngIf="option.numberOfAdults">
  <div class="d-flex justify-content-between">
    <p class="title">Price per adult</p>
    <p data-id="sold-amount-per-adult">{{ option.soldAmountPerAdult | portalCurrency }}</p>
  </div>

  <div class="d-flex justify-content-between mt-1">
    <p class="title">Number of adults</p>
    <p data-id="number-of-adults">{{ option.numberOfAdults }}</p>
  </div>

  <hr class="my-2 hr" />
</ng-container>

<ng-container *ngIf="option.numberOfChildren">
  <div class="d-flex justify-content-between">
    <p class="title">Price per child</p>
    <p data-id="sold-amount-per-child">{{ option.soldAmountPerChild | portalCurrency }}</p>
  </div>

  <div class="d-flex justify-content-between mt-1">
    <p class="title">Number of children</p>
    <p data-id="number-of-children">{{ option.numberOfChildren }}</p>
  </div>

  <hr class="my-2 hr" />
</ng-container>

<ng-container *ngIf="option.numberOfInfants">
  <div class="d-flex justify-content-between">
    <p class="title">Price per infant</p>
    <p data-id="sold-amount-per-infant">{{ option.soldAmountPerInfant | portalCurrency }}</p>
  </div>

  <div class="d-flex justify-content-between mt-1">
    <p class="title">Number of infants</p>
    <p data-id="number-of-infants">{{ option.numberOfInfants }}</p>
  </div>

  <hr class="my-2 hr" />
</ng-container>

<div class="d-flex justify-content-between">
  <p class="title">Total price</p>
  <p data-id="total">{{ total | portalCurrency }}</p>
</div>
