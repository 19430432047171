import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withoutTimezone',
})
export class WithoutTimezonePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string, format = 'MMM d, yyyy') {
    if (value) {
      const datetime = value.substring(0, value.length - 6).replace(/-/g, '/');
      const date = new Date(Date.parse(datetime))?.toISOString();

      return this.datePipe.transform(date, format) || '';
    }
    return '';
  }
}
