import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'cc-expiration-field',
  templateUrl: './cc-expiration-field.component.html',
  styleUrls: ['./cc-expiration-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcExpirationFieldComponent extends FieldType<FieldTypeConfig> {}
