import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

import { Subscription } from 'rxjs';
import { Option } from '@app/forms/formly/formly-utils';

@Component({
  selector: 'radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFieldComponent
  extends FieldType<FieldTypeConfig<FormlyFieldConfig['props'] & { options: Option[] }>>
  implements OnInit, OnDestroy
{
  valueChanges$: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.valueChanges$ = this.formControl.valueChanges.subscribe(() =>
      this.changeDetectorRef.detectChanges()
    );
  }

  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
  }
}
