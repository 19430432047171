import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'price-summary',
  templateUrl: './price-summary.component.html',
  styleUrls: ['./price-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSummaryComponent {
  @Input() option: PortalOrderNode;
  @Input() disruptionProtection?: number;
  @Input() tipsAmount?: number;
  @Input() cancelForAnyReason?: number;

  get total() {
    return (
      (this.option.soldAmount || 0) +
      (this.disruptionProtection || 0) +
      (this.tipsAmount || 0) +
      (this.cancelForAnyReason || 0)
    );
  }
}
