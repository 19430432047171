<div class="d-flex align-items-center">
  <img src="assets/icons/star-blue.svg" width="20" height="20" alt="info" class="mb-auto mb-md-0" />

  <p class="ml-2 semi-bold text-bleu-de-france-600">Throwaway Ticketing</p>
</div>

<div class="d-flex mt-2">
  <p class="text-jazlyn-700">
    You’ll get return ticket <span data-id="from">{{ itinerary.departureAirport.cityName }}</span> –
    <span data-id="to">{{ itinerary.arrivalAirport.cityName }}</span> because this flight is cheaper
    as a round-trip ticket, but you’re free to use only the flights you need.
    <br class="d-md-none" />
    <a
      (click)="$event.stopPropagation(); show(modalTemplate)"
      class="link underline text-bleu-de-france-600 d-flex d-md-inline mt-2 mt-md-0"
      data-id="more"
      >Learn more</a
    >
  </p>
</div>

<ng-template #modalTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column flex-md-row align-items-md-center p-4 p-md-6 header">
      <p (click)="hide()" class="d-md-none d-flex align-items-center mb-4" data-id="hide">
        <img src="assets/icons/chevron_left.svg" alt="chevron" />
        <a class="link text-jazlyn-700 ml-1">Back</a>
      </p>

      <h2 class="semi-bold">Throwaway Ticketing: Clever Airfare Savings</h2>

      <img
        (click)="hide()"
        src="assets/icons/close.svg"
        width="20"
        height="20"
        class="d-none d-md-inline ml-auto cursor-pointer"
        alt="close"
        data-id="hide"
      />
    </div>

    <div class="p-4 p-md-6">
      <div class="py-4 py-md-6 d-flex flex-column align-items-center info">
        <p class="caption semi-bold text-sun-crete-600">You use the outbound flight only</p>

        <img
          src="assets/icons/arrow_down-orange.svg"
          width="24"
          height="24"
          alt="arrow"
          class="mt-1"
        />

        <div class="d-flex align-items-center justify-content-center mt-4">
          <p class="caption semi-bold mr-md-5">Airport A</p>

          <img src="assets/images/arrows.svg" width="170" height="78" alt="arrows" />

          <p class="caption semi-bold ml-md-5">Airport B</p>
        </div>
      </div>

      <p class="mt-4 mt-md-6">
        Consider the Throwaway Ticketing for an opportunity to save on airfare.<br
          class="d-none d-md-inline"
        />
        This approach involves booking a roundtrip ticket but only using the<br
          class="d-none d-md-inline"
        />
        outbound flight. It’s a clever way to possibly find lower fares for your<br
          class="d-none d-md-inline"
        />
        one-way journey.
      </p>

      <div class="mt-4 d-flex flex-column">
        <div class="d-flex">
          <span class="quotes-dot mr-2"></span>
          <p>Ideal for specific one-way travel needs.</p>
        </div>

        <div class="d-flex mt-3">
          <span class="quotes-dot mr-2"></span>
          <p>
            Plan accordingly, as the unused part of your ticket will<br
              class="d-none d-md-inline"
            />
            be automatically cancelled by the airline.
          </p>
        </div>

        <div class="d-flex mt-3">
          <span class="quotes-dot mr-2"></span>
          <p>
            A smart option for cost-conscious travelers who value<br class="d-none d-md-inline" />
            economy over flexibility.
          </p>
        </div>
      </div>

      <div class="mt-4 px-4 py-3 d-flex flex-column note">
        <div class="d-flex align-items-center">
          <img src="assets/icons/error-blue.svg" width="20" height="20" alt="note" />
          <p class="semi-bold ml-1 text-travalign-brand-blue">Note</p>
        </div>

        <p class="mt-1">
          This trick focuses on cost-efficiency, though it’s not widely<br
            class="d-none d-md-inline"
          />
          supported by airlines. Employing this method can lead to savings,<br
            class="d-none d-md-inline"
          />
          but it’s essential to be aware of airline policies.
        </p>
      </div>
    </div>
  </div>
</ng-template>
