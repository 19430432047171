import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';

import { TooltipComponent } from '@app/ui/components/tooltip/tooltip.component';
import { PopupsService } from '@app/ui/services/popups.service';
import { containerWidth } from '@app/utils/constants';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  componentRef?: ComponentRef<TooltipComponent>;

  @Input() tooltip: TemplateRef<unknown>;

  constructor(private elementRef: ElementRef, private popupsService: PopupsService) {}

  @HostListener('mouseover') mouseover() {
    this.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.hide();
  }

  show() {
    if (this.componentRef || window.innerWidth < containerWidth) return;

    this.componentRef = this.popupsService.showTooltip(this.tooltip);

    this.updatePosition();
  }

  hide() {
    if (this.componentRef) {
      this.popupsService.hide(this.componentRef);

      delete this.componentRef;
    }
  }

  updatePosition() {
    if (this.componentRef) {
      const nativeElementRect = (
        this.elementRef.nativeElement as HTMLElement
      ).getBoundingClientRect();

      Object.assign(this.componentRef.instance, {
        top: nativeElementRect.top + nativeElementRect.height / 2,
        left: nativeElementRect.left + nativeElementRect.width,
      });
    }
  }
}
