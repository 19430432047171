<div class="d-flex flex-column flex-md-row align-items-md-center">
  <div class="d-flex align-items-center">
    <img [src]="longestSegment.flightSegment.airline.logoUrl" alt="logo" class="logo" />

    <p class="ml-2 d-md-none" data-id="airline-short-name">
      {{ longestSegment.flightSegment.airline.shortName }}
    </p>
  </div>

  <div class="d-flex align-items-start justify-content-between mt-2 mt-md-0 ml-md-4 w-100">
    <div class="d-flex flex-column cell">
      <p data-id="departure-at">
        {{ itinerary.segments[0].flightSegment.departureAtAirportTimeZone | withoutTimezone: 'h:mm
        a' }}
      </p>
      <p class="mt-1 text-jazlyn-700">
        <span data-id="departure-airport">{{ itinerary.departureAirport.locationCode }}</span
        ><dot-separator class="mx-2"></dot-separator
        ><span data-id="departure-at-date"
          >{{ itinerary.segments[0].flightSegment.departureAtAirportTimeZone | withoutTimezone: 'MMM
          d' }}</span
        >
      </p>
    </div>

    <div class="d-flex flex-column cell">
      <p data-id="arrival-at">
        {{ itinerary.segments[itinerary.segments.length - 1].flightSegment.arrivalAtAirportTimeZone
        | withoutTimezone: 'h:mm a' }}
      </p>
      <p class="mt-1 text-jazlyn-700">
        <span data-id="arrival-airport">{{ itinerary.finalDestinationAirport.locationCode }}</span
        ><dot-separator class="mx-2"></dot-separator
        ><span
          [ngClass]="{ 'text-sun-crete-700': (itinerary.daysShift | hasDaysShift) || (itinerary | hasDaysShiftAfterLayover) }"
          data-id="arrival-at-date"
          >{{ itinerary.segments[itinerary.segments.length -
          1].flightSegment.arrivalAtAirportTimeZone | withoutTimezone: 'MMM d' }}</span
        >
      </p>
    </div>

    <div class="d-flex flex-column cell">
      <p data-id="travel-time">{{ itinerary.travelTime | travelTime }}</p>
      <p class="mt-1 text-jazlyn-700 d-flex flex-column flex-md-row">
        <span data-id="service-class" class="service-class"
          >{{ mixedClasses ? 'Mixed' : (longestSegment.flightSegment.serviceClass | label) }}</span
        ><span class="mx-2 d-none d-md-flex align-items-center"
          ><dot-separator></dot-separator
        ></span>
        <span data-id="segments"
          ><ng-container [ngSwitch]="itinerary.segments.length - 1">
            <ng-container *ngSwitchCase="0">Direct</ng-container>
            <ng-container *ngSwitchCase="1">1 stop</ng-container>
            <ng-container *ngSwitchDefault>{{ itinerary.segments.length - 1 }} stops</ng-container>
          </ng-container></span
        >
      </p>
    </div>
  </div>
</div>
