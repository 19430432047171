import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'prices-tooltip',
  templateUrl: './prices-tooltip.component.html',
  styleUrls: ['./prices-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricesTooltipComponent {
  @Input() option: PortalOrderNode;

  get total() {
    const soldAmountPerAdult = this.option.soldAmountPerAdult || 0;
    const soldAmountPerChild = this.option.soldAmountPerChild || 0;
    const soldAmountPerInfant = this.option.soldAmountPerInfant || 0;

    const numberOfAdults = this.option.numberOfAdults || 0;
    const numberOfChildren = this.option.numberOfChildren || 0;
    const numberOfInfants = this.option.numberOfInfants || 0;

    return (
      soldAmountPerAdult * numberOfAdults +
      soldAmountPerChild * numberOfChildren +
      soldAmountPerInfant * numberOfInfants
    );
  }
}
