import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, map, Observable, Subject, takeUntil } from 'rxjs';

import { PortalUserVerificationNode } from '@app/services/api/api.types';
import { intervalMs } from '@app/utils/constants';
import { getDomain } from '@app/utils/utils';

@Component({
  selector: 'activation-code-modal',
  templateUrl: './activation-code-modal.component.html',
  styleUrls: ['./activation-code-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationCodeModalComponent implements OnInit, OnChanges, AfterViewInit {
  domain: { label: string; url: string } | null;
  form: FormGroup;

  timer$: Observable<number>;

  loading: boolean;

  @Input() activation: PortalUserVerificationNode;
  @Input() activationPassed: boolean;
  @Input() activationError: string | null;

  @Output() hideModal = new EventEmitter();
  @Output() validateCode = new EventEmitter();
  @Output() sendActivationEmail = new EventEmitter();

  ngOnInit() {
    this.domain = getDomain(this.activation?.emailMasked);

    this.form = new FormGroup({
      digit1: new FormControl('', Validators.pattern('[0-9]')),
      digit2: new FormControl('', Validators.pattern('[0-9]')),
      digit3: new FormControl('', Validators.pattern('[0-9]')),
      digit4: new FormControl('', Validators.pattern('[0-9]')),
      digit5: new FormControl('', Validators.pattern('[0-9]')),
      digit6: new FormControl('', Validators.pattern('[0-9]')),
    });

    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['activationError']?.currentValue === 'string') {
      this.form.enable();

      this.form.setValue({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: '',
        digit5: '',
        digit6: '',
      });
      this.form.setErrors({});
      this.focusFirstInput();
    }

    if (changes['activationPassed']?.currentValue === true) {
      setTimeout(() => this.hideModal.emit(), 1000);
    }
  }

  ngAfterViewInit() {
    this.focusFirstInput();
  }

  focusFirstInput() {
    setTimeout(() => document.getElementById('digit1')?.focus());
  }

  onChange(event: Event) {
    this.form.setErrors(null);

    const input = event.target as HTMLInputElement;
    const nextInput = input.nextElementSibling as HTMLInputElement;

    input.value = input.value.replace(/[^0-9]/g, '');

    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }

    if (this.form.valid) {
      this.onSubmit();
    }
  }

  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text') || '';
    const firstMatch = pastedText.match(/\d{6}/);

    if (firstMatch?.length) {
      this.form.setValue({
        digit1: firstMatch[0][0],
        digit2: firstMatch[0][1],
        digit3: firstMatch[0][2],
        digit4: firstMatch[0][3],
        digit5: firstMatch[0][4],
        digit6: firstMatch[0][5],
      });

      let input = event.target as HTMLInputElement;

      while (input.nextElementSibling) {
        input = input.nextElementSibling as HTMLInputElement;
      }

      input?.focus();

      this.onSubmit();
    }
  }

  onSubmit() {
    this.form.disable();

    const code = Object.values(this.form.value as string[]).join('');
    this.validateCode.emit(code);
  }

  sendVerificationEmail() {
    if (!this.loading) {
      this.sendActivationEmail.emit();
    }

    this.loading = true;
  }

  startCountdown() {
    const destroy$ = new Subject<void>();

    const endTime =
      new Date(`${this.activation.emailSentAt.split('+')[0]}+00:00`).getTime() + intervalMs; // todo fix after be

    this.timer$ = interval(1000)
      .pipe(takeUntil(destroy$))
      .pipe(
        map(() => {
          const now = new Date().getTime();

          const currentTime = endTime - now;

          if (currentTime <= 0) {
            setTimeout(() => {
              destroy$.next();
              destroy$.complete();
            });
          }

          return currentTime;
        })
      );
  }
}
