import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PortalBookingStatusTypeEnum } from '@app/services/api/api.types';

@Component({
  selector: 'booking-status',
  templateUrl: './booking-status.component.html',
  styleUrls: ['./booking-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingStatusComponent {
  PortalBookingStatusTypeEnum = PortalBookingStatusTypeEnum;

  @Input() status: PortalBookingStatusTypeEnum;

  @HostBinding('class.time') get time() {
    return this.status === PortalBookingStatusTypeEnum.InProgress;
  }

  @HostBinding('class.check') get check() {
    return this.status !== PortalBookingStatusTypeEnum.InProgress;
  }
}
