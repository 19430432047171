import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[preventTouchmove]',
})
export class PreventTouchmoveDirective {
  @HostListener('touchstart', ['$event'])
  @HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  touch(event: MouseEvent) {
    const nativeElement = this.elementRef?.nativeElement as HTMLElement;

    if (nativeElement?.scrollTop) {
      event.stopPropagation();
    }
  }

  constructor(private elementRef: ElementRef<unknown>) {}
}
