import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ask-to-sign-in-modal',
  templateUrl: './ask-to-sign-in-modal.component.html',
  styleUrls: ['./ask-to-sign-in-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskToSignInModalComponent {
  @Output() hideModal = new EventEmitter();
  @Output() continueAsGuest = new EventEmitter();
  @Output() showSignInModal = new EventEmitter();
}
