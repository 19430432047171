import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { PortalFlightItineraryGroupNode } from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';

@Component({
  selector: 'bonus-flight',
  templateUrl: './bonus-flight.component.html',
  styleUrls: ['./bonus-flight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusFlightComponent {
  @Input() itinerary: PortalFlightItineraryGroupNode;

  constructor(private popupsService: PopupsService) {}

  show(template: TemplateRef<unknown>) {
    this.popupsService.showModal(template);
  }

  hide() {
    this.popupsService.hideLast();
  }
}
