<h2 class="semi-bold d-md-none">
  <span data-id="from">{{ departureAirportCityName }}</span>&nbsp;&ndash;&nbsp;<span data-id="to"
    >{{ arrivalAirportCityName }}</span
  >
</h2>

<h1 class="semi-bold d-none d-md-flex">
  <span data-id="from">{{ departureAirportCityName }}</span>&nbsp;&ndash;&nbsp;<span data-id="to"
    >{{ arrivalAirportCityName }}</span
  >
</h1>

<div class="d-flex align-items-center mt-2 text-jazlyn-700 flex-wrap">
  <p data-id="service-class">{{ serviceClass | label }}</p>
  <dot-separator class="mx-2"></dot-separator>

  <ng-container *ngIf="portalLead?.tripType">
    <ng-container [ngSwitch]="portalLead?.tripType">
      <p *ngSwitchCase="PortalTripType.Oneway" data-id="trip-type">One way</p>
      <p *ngSwitchCase="PortalTripType.Round" data-id="trip-type">Round trip</p>
      <p *ngSwitchCase="PortalTripType.Multi" data-id="trip-type">Multi-city</p>
    </ng-container>

    <dot-separator class="mx-2"></dot-separator>
  </ng-container>

  <p data-id="departure-at">{{ departureAt | date: 'MMM d' }}</p>
  <ng-container *ngIf="portalLead?.roundTripDepartureAt"
    >–
    <p data-id="round-trip-departure-at">
      {{ portalLead?.roundTripDepartureAt | date: 'MMM d' }}
    </p></ng-container
  >
  <dot-separator class="mx-2"></dot-separator>

  <p class="text" data-id="pax-count">
    {{ passengerCount }}
    <ng-container [ngPlural]="passengerCount || 0">
      <ng-template ngPluralCase="=1">traveler</ng-template>
      <ng-template ngPluralCase="other">travelers</ng-template>
    </ng-container>
  </p>
</div>
