import { ComponentRef, Directive, HostListener, Input, TemplateRef } from '@angular/core';

import { ModalComponent } from '@app/ui/components/modal/modal.component';
import { PopupsService } from '@app/ui/services/popups.service';
import { containerWidth } from '@app/utils/constants';

@Directive({
  selector: '[modal]',
})
export class ModalDirective {
  componentRef?: ComponentRef<ModalComponent>;

  @Input() modal: TemplateRef<unknown>;

  constructor(private popupsService: PopupsService) {}

  @HostListener('click', ['$event']) click(event: MouseEvent) {
    event.stopPropagation();

    this.show();
  }

  show() {
    if (window.innerWidth >= containerWidth) return;

    this.componentRef = this.popupsService.showModal(this.modal);
  }
}
