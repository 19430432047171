<div class="d-flex flex-column flex-md-row">
  <div class="d-flex align-items-start flex-1">
    <div class="d-flex align-items-center justify-content-center p-3 quote">
      <img src="assets/icons/quote.svg" alt="quote" width="16" height="16" />
    </div>

    <div class="d-flex flex-column ml-3">
      <p class="italic">
        {{ salesAgent.fullName }} is the best travel agent I’ve ever met! Exceptionally supportive
        and can help you not only to find tickets with savings but also can assist you in planning
        the future trips.
      </p>

      <p class="semi-bold mt-4">Laurence Carson</p>
      <p>Traveled NYC–CPT</p>
    </div>
  </div>

  <div class="d-flex align-items-start flex-1 ml-md-8 mt-6 mt-md-0">
    <div class="d-flex align-items-center justify-content-center p-3 quote">
      <img src="assets/icons/quote.svg" alt="quote" width="16" height="16" />
    </div>

    <div class="d-flex flex-column ml-3">
      <p class="italic">
        Working with {{ salesAgent.fullName | getFirstName }} was a fantastic experience! We enjoyed
        a smooth travel process, received great tips and insights on what to do in Amsterdam, and
        saved $720 USD compared to the best flight options online.
      </p>

      <p class="semi-bold mt-4">Jessica Garcia</p>
      <p>Traveled LAX–AMS</p>
    </div>
  </div>
</div>
