<card
  *ngFor="let field of field.fieldGroup; let index = index; let last = last"
  [ngClass]="{ 'mb-4': !last }"
  class="flex-column p-4"
>
  <p
    *ngIf="field.props?.['labels'] && field.props?.['labels'][index]"
    class="semi-bold mb-4"
    data-id="label"
  >
    {{ field.props?.['labels'][index] }}
  </p>

  <formly-field [field]="field" class="w-100"></formly-field>
</card>
