<input
  [formControl]="formControl"
  [formlyAttributes]="field"
  [ngClass]="{ 'error': formControl.touched && formControl.errors }"
  [attr.data-id]="field.key"
  class="input"
  autocomplete="off"
  maxlength="7"
  ccExp
/>
