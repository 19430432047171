import { Pipe, PipeTransform } from '@angular/core';

import { PortalFlightItineraryGroupNode } from '@app/services/api/api.types';
import { DaysShiftAfterLayoverPipe } from '@app/pipes/days-shift-after-layover.pipe';

@Pipe({
  name: 'hasDaysShiftAfterLayover',
})
export class HasDaysShiftAfterLayoverPipe implements PipeTransform {
  constructor(private daysShiftAfterLayoverPipe: DaysShiftAfterLayoverPipe) {}

  transform(itinerary: PortalFlightItineraryGroupNode) {
    return itinerary.segments.some((_, index) =>
      this.daysShiftAfterLayoverPipe.transform(itinerary.segments, index)
    );
  }
}
