<card class="d-flex flex-column p-4">
  <div class="d-flex justify-content-between mb-4">
    <p class="semi-bold">Debit or credit card</p>

    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center justify-content-center card">
        <img src="assets/images/mc.svg" alt="mc" class="m-auto" />
      </div>

      <div class="d-flex align-items-center justify-content-center card ml-2">
        <img src="assets/images/visa.svg" alt="visa" class="m-auto" />
      </div>

      <div class="d-flex align-items-center justify-content-end card ml-2">
        <img src="assets/images/amex.svg" alt="amex" class="m-auto mr-0" />
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <form [formGroup]="form">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>

    <div *ngIf="form.touched && form.errors" class="d-none d-md-block">
      <form-error
        *ngFor="let error of form.errors | keyvalue"
        [error]="error"
        class="mt-4"
        data-id="error"
      ></form-error>
    </div>
  </div>

  <div class="d-md-none p-fixed errors">
    <div *ngIf="form.touched && form.errors" class="mx-4">
      <form-error
        *ngFor="let error of form.errors | keyvalue"
        [error]="error"
        class="mb-2"
        data-id="error"
      ></form-error>
    </div>
  </div>
</card>

<ng-content></ng-content>

<div class="mt-4 mt-md-6 mx-4 mx-md-0 d-flex flex-column-reverse flex-md-row">
  <button
    (click)="back.emit()"
    class="button secondary mt-4 mt-md-0 d-flex align-items-center justify-content-center"
    data-id="back"
  >
    <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
    Back
  </button>

  <button
    (click)="submit()"
    [disabled]="form.disabled"
    class="button ml-md-auto d-flex align-items-center justify-content-center"
    data-id="submit"
  >
    Pay
    <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
  </button>
</div>
