import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Hub } from '@aws-amplify/core';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
  constructor() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        window.location.href = data as string;
      }
    });
  }
}
