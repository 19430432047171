<select
  (ngModelChange)="selectOption.emit($event); markAsTouched.emit();"
  (blur)="markAsTouched.emit()"
  [ngModel]="value"
  [disabled]="disabled"
  [ngClass]="{
    'error': touched && errors,
    'disabled': disabled,
    'text-jazlyn-700': !value
  }"
  class="input"
>
  <option [value]="undefined" disabled>{{ placeholder }}</option>

  <ng-container *ngFor="let option of cachedOptions">
    <optgroup
      *ngIf="option.value === dropdownSeparatorValue; else optionTemplate"
      label="--------------------"
    ></optgroup>

    <ng-template #optionTemplate>
      <option [selected]="option.value === value" [value]="option.value">{{ option.label }}</option>
    </ng-template>
  </ng-container>
</select>

<img
  [ngClass]="{ 'cursor-pointer': !disabled }"
  src="assets/icons/chevron_down.svg"
  alt="chevron"
  class="chevron"
/>
