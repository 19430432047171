import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalLeadNode, PortalOrderNode, PortalTripType } from '@app/services/api/api.types';

@Component({
  selector: 'flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightInfoComponent {
  PortalTripType = PortalTripType;

  @Input() portalLead?: PortalLeadNode;
  @Input() portalOrder?: PortalOrderNode;

  get departureAirportCityName() {
    return (
      this.portalLead?.firstTrip?.departureAirport?.cityName ||
      this.portalOrder?.flightItineraries[0].departureAirport.cityName
    );
  }

  get arrivalAirportCityName() {
    return (
      this.portalLead?.firstTrip?.arrivalAirport?.cityName ||
      this.portalOrder?.flightItineraries[0].arrivalAirport.cityName
    );
  }

  get serviceClass() {
    return (
      this.portalLead?.firstTrip?.serviceClass ||
      this.portalOrder?.flightItineraries[0].segments[0].flightSegment.serviceClass
    );
  }

  get departureAt() {
    return (
      this.portalLead?.firstTrip?.departureAt || this.portalOrder?.flightItineraries[0].departureAt
    );
  }

  get passengerCount() {
    return this.portalLead?.firstTrip?.passengerCount || this.portalOrder?.totalPassengers;
  }
}
