import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { PopupsOptions, PopupsService } from '@app/ui/services/popups.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  touchstartEvent: TouchEvent;

  @Input() content: TemplateRef<unknown>;
  @Input() options: PopupsOptions;
  @Input() componentRef: ComponentRef<ModalComponent>;

  @ViewChild('modal') modal: ElementRef<HTMLElement>;

  @HostBinding('class.hide-swap') isHideSwap: boolean;

  constructor(private popupsService: PopupsService) {}

  hide() {
    this.popupsService.hide(this.componentRef);
  }

  touchstart(event: TouchEvent) {
    this.touchstartEvent = event;

    if (this.modal.nativeElement) {
      this.modal.nativeElement.style.transition = `initial`;
    }
  }

  touchmove(event: TouchEvent) {
    const touchstartY = this.touchstartEvent?.touches[0]?.clientY;
    const touchendY = event.touches[0]?.clientY;

    const range = touchendY - touchstartY;

    if (this.modal.nativeElement && range > 0) {
      this.modal.nativeElement.style.transform = `translateY(${range}px)`;
    }
  }

  touchend(event: TouchEvent) {
    if (this.modal.nativeElement) {
      const touchstartY = this.touchstartEvent?.touches[0]?.clientY;
      const touchendY = event.changedTouches[0]?.clientY;

      const range = touchendY - touchstartY;
      const swipeTime = event.timeStamp - this.touchstartEvent?.timeStamp;

      const hideSwap = swipeTime < maxHideSwipeTime && range > maxHideSwipeRange;

      const hideTouchend = this.modal.nativeElement.offsetHeight - range < minModalHeightToHide;

      if (hideTouchend) {
        this.hide();
      } else if (hideSwap) {
        this.hideSwap();
      } else {
        this.bounce();
      }

      this.modal.nativeElement.style.transition = ``;
    }
  }

  hideSwap() {
    this.isHideSwap = true;

    this.modal.nativeElement.style.transform = `translateY(${this.modal.nativeElement.clientHeight}px)`;

    setTimeout(() => this.hide(), 150);
  }

  bounce() {
    this.modal.nativeElement.style.transform = ``;
  }
}

export const maxHideSwipeTime = 200;
export const maxHideSwipeRange = 50;
export const minModalHeightToHide = 150;
