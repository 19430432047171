<div class="w-100 d-flex align-items-center p-4 p-relative header">
  <h2 class="semi-bold">Flight details</h2>
</div>

<div preventTouchmove class="content">
  <portal-option-details [option]="option" [portalLink]="portalLink"></portal-option-details>

  <hotel-bookings
    *ngIf="option.hotelBookings?.length"
    [hotelBookings]="option.hotelBookings"
  ></hotel-bookings>
</div>

<div class="d-flex align-items-center justify-content-center p-4 footer">
  <ng-container [ngSwitch]="option.bookingStatus">
    <button
      *ngSwitchCase="PortalBookingStatusTypeEnum.Option"
      (click)="requestToBook.emit()"
      class="button w-100"
      data-id="request-to-book"
      id="submit-{{ option.portalLink }}"
    >
      Select
    </button>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.Booked"
      [status]="PortalBookingStatusTypeEnum.Booked"
      class="mr-auto"
    ></booking-status>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.InProgress"
      [status]="PortalBookingStatusTypeEnum.InProgress"
      class="mr-auto"
    ></booking-status>

    <booking-status
      *ngSwitchCase="PortalBookingStatusTypeEnum.Issued"
      [status]="PortalBookingStatusTypeEnum.Issued"
      class="mr-auto"
    ></booking-status>
  </ng-container>
</div>
