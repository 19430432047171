import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { Formdata } from '@app/forms/formly/formly-utils';
import { errorsFormTexts } from '@app/forms/formly/validators/validators';
import { scrollTo } from '@app/utils/utils';

@Component({ template: '' })
export class BaseFormComponent<E = null> implements OnInit, OnChanges {
  form = new FormGroup({});

  fields: FormlyFieldConfig[] = [];

  @Input() model: Formdata = {};
  @Input() errors: E;

  @Output() formSubmit = new EventEmitter<Formdata>();

  ngOnInit() {
    this.fields = this.getFields();
  }

  getFields(): FormlyFieldConfig[] {
    return [];
  }

  ngOnChanges(changes: SimpleChanges) {
    const errors = changes['errors']?.currentValue as E;

    if (errors) {
      this.setErrors(errors);
    }
  }

  setErrors(errors: E) {
    console.error(errors);
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.form.disable({ emitEvent: false });
      this.formSubmit.emit(this.model);
    } else {
      this.form.setErrors({ reqired: errorsFormTexts.required });
      this.scrollToFirstError();
    }
  }

  scrollToFirstError() {
    setTimeout(() => {
      const inputsWithError = document.querySelectorAll('.input.error');

      if (inputsWithError?.length) {
        scrollTo(inputsWithError[0]);
      }
    });
  }
}
