<ng-container *ngIf="portalLead$ | async as portalLead; else loadingTemplate">
  <header
    (signIn)="showSignInModal()"
    (signOut)="signOut()"
    [salesAgent]="portalLead.salesAgent"
    [showLoginButton]="showLoginButton"
    class="header"
  ></header>

  <div class="mx-auto pt-4 pt-md-10 content">
    <router-outlet></router-outlet>
  </div>

  <footer></footer>
</ng-container>

<ng-template #loadingTemplate>
  <lead-loading-page></lead-loading-page>
</ng-template>

<ng-template #signInTemplate>
  <sign-in-modal
    (hideModal)="hideModal()"
    (continueWithGoogle)="continueWith(CognitoHostedUIIdentityProvider.Google)"
    (continueWithFacebook)="continueWith(CognitoHostedUIIdentityProvider.Facebook)"
    (continueWithEmail)="continueWithEmail()"
  ></sign-in-modal>
</ng-template>

<ng-template #verificationTemplate>
  <verification-modal
    (validateCode)="validateCode($event)"
    (portalSendVerificationEmail)="portalSendVerificationEmail()"
    (hideModal)="hideModal()"
    [verification]="verification"
    [verificationPassed]="verificationPassed"
    [verificationError]="verificationError"
  ></verification-modal>
</ng-template>

<ng-template #continueWithEmailTemplate>
  <continue-with-email-modal
    (hideModal)="hideModal()"
    (sendEmail)="sendEmail($event)"
    [activationError]="activationError"
  ></continue-with-email-modal>
</ng-template>

<ng-template #activationCodeTemplate>
  <activation-code-modal
    (validateCode)="validateNewEmailCode($event)"
    (sendActivationEmail)="sendEmail(email)"
    (hideModal)="hideModal()"
    [activation]="activation"
    [activationPassed]="activationPassed"
    [activationError]="activationError"
  ></activation-code-modal>
</ng-template>
