<div class="d-flex flex-column flex-1 mr-md-4">
  <p class="text-jazlyn-700 mb-1">Month of birth</p>

  <dropdown
    data-id="month-of-birth"
    [value]="month"
    [touched]="formControl.touched"
    [disabled]="formControl.disabled"
    [errors]="formControl.errors"
    [options]="months"
    (selectOption)="setMonth($event)"
    (markAsTouched)="touchedMonth = true; setMarkAsTouched()"
  ></dropdown>
</div>

<div class="d-flex flex-column flex-1 mt-4 mt-md-0 mr-md-4">
  <p class="text-jazlyn-700 mb-1">Day of birth</p>

  <dropdown
    data-id="day-of-birth"
    [value]="day"
    [touched]="formControl.touched"
    [disabled]="formControl.disabled"
    [errors]="formControl.errors"
    [options]="days"
    (selectOption)="setDay($event)"
    (markAsTouched)="touchedDay = true; setMarkAsTouched()"
  ></dropdown>
</div>

<div class="d-flex flex-column flex-1 mt-4 mt-md-0">
  <p class="text-jazlyn-700 mb-1">Year of birth</p>

  <dropdown
    data-id="year-of-birth"
    [value]="year"
    [touched]="formControl.touched"
    [disabled]="formControl.disabled"
    [errors]="formControl.errors"
    [options]="years"
    (selectOption)="setYear($event)"
    (markAsTouched)="touchedYear = true; setMarkAsTouched()"
  ></dropdown>
</div>
