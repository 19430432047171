import { ChangeDetectionStrategy, Component } from '@angular/core';

import { redirectUrlKey } from '@app/services/auth/auth.service';

@Component({
  selector: 'logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutPageComponent {
  constructor() {
    const redirectUrl = sessionStorage.getItem(redirectUrlKey) || '';

    window.location.href = redirectUrl;

    sessionStorage.removeItem(redirectUrlKey);
  }
}
