import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'throwaway-ticketing-tooltip',
  templateUrl: './throwaway-ticketing-tooltip.component.html',
  styleUrls: ['./throwaway-ticketing-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThrowawayTicketingTooltipComponent {
  @Input() option: PortalOrderNode;
}
