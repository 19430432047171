import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { catchError, combineLatest, EMPTY, map, Observable, take } from 'rxjs';
import { Router } from '@angular/router';

import { Steps } from '@app/components/stepper/stepper.component';
import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import {
  PortalCreditCardNodeInput,
  PortalFeatures,
  PortalLeadNode,
  PortalOrderNode,
  PortalPassengerNodeInput,
} from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';
import { State, StateService } from '@app/services/state/state.service';
import { beforeUnloadHandler } from '@app/utils/utils';
import { ApiService } from '@app/services/api/api.service';
import { CreditCardErrors } from '@app/forms/card-form/card-form.component';
import { newCreditCardOption } from '@app/utils/constants';
import { Option } from '@app/forms/formly/formly-utils';

@Component({
  selector: 'overview-and-payment-page',
  templateUrl: './overview-and-payment-page.component.html',
  styleUrls: ['./overview-and-payment-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewAndPaymentPageComponent implements OnInit, OnDestroy {
  Steps = Steps;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;
  state: State;
  clientCreditCards: Option[];

  model: PortalCreditCardNodeInput = { cvv2: '' };
  errors: CreditCardErrors | null;

  formHasChanges: boolean;
  withPayAndConfirm: boolean;

  readAndAccept: boolean;
  readAndAcceptError: boolean;

  @ViewChild('confirmTemplate') confirmTemplate: TemplateRef<unknown>;

  constructor(
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private changeDetectorRef: ChangeDetectorRef,
    private stateService: StateService,
    private popupsService: PopupsService,
    private apiService: ApiService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = combineLatest([
      this.portalLeadService.portalLead$,
      this.portalOrderService.portalOrder$,
    ]).pipe(
      map(([portalLead, portalOrder]) => {
        if (portalLead) {
          this.withPayAndConfirm = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_cc
          );
        }

        if (portalOrder) {
          this.portalOrder = portalOrder;
        }

        this.state = this.stateService.getState();
        this.clientCreditCards = this.stateService.getClientCreditCards();

        return portalLead;
      })
    );
  }

  valueChange() {
    this.formHasChanges = true;
    window.addEventListener('beforeunload', beforeUnloadHandler);
  }

  tryBack() {
    if (this.formHasChanges) {
      this.popupsService.showModal(this.confirmTemplate);
      return;
    }

    this.back();
  }

  back() {
    this.location.back();
  }

  formSubmit(creditCard: PortalCreditCardNodeInput) {
    const input = {
      portalLink: this.portalOrder.portalLink,
      passengers: this.state.passengers,
      creditCard,
    };

    if (this.state.tipsAmount) {
      Object.assign(input, { tipsAmount: this.state.tipsAmount });
    }

    if (this.state.disruptionProtection) {
      Object.assign(input, { disruptionProtection: !!this.state.disruptionProtection });
    }

    if (this.state.cancelForAnyReason) {
      Object.assign(input, { cancelForAnyReason: !!this.state.cancelForAnyReason });
    }

    const isNewCreditCard = creditCard.id === newCreditCardOption.value || !creditCard.id;

    input.creditCard = isNewCreditCard ? creditCard : { id: creditCard.id, cvv2: creditCard.cvv2 };

    if (isNewCreditCard) {
      delete input.creditCard['id'];
    }

    return this.portalSubmitOption(input);
  }

  portalSubmitOption(input: {
    passengers: PortalPassengerNodeInput[];
    portalLink: string;
    tipsAmount?: number;
    disruptionProtection?: boolean;
    cancelForAnyReason?: boolean;
    creditCard: PortalCreditCardNodeInput;
  }) {
    return this.apiService
      .portalSubmitOption({ input })
      .pipe(
        take(1),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        if (response?.ok) {
          this.portalLeadService.getPortalLead({ portalLink: this.state.portalLink });

          this.continue();
        } else {
          this.errors = JSON.parse(response.errors) as CreditCardErrors;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  continue() {
    void this.router.navigate([
      `${this.state.portalLink}`,
      `${this.portalOrder.portalLink}`,
      `thank-you`,
    ]);
  }

  selectReadAndAccept(readAndAccept: boolean) {
    this.readAndAccept = readAndAccept;
    this.readAndAcceptError = false;
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  }
}
