<div
  (click)="expanded = !expanded"
  class="d-flex d-md-none align-items-start justify-content-between flex-1"
>
  <div *ngIf="expanded" class="d-flex flex-column">
    <div
      *ngFor="let step of steps; let first = first"
      [ngClass]="{ 'mt-2': !first }"
      class="d-flex align-items-center"
    >
      <p *ngIf="activeIndex <= step.index" class="w-20 text-align-center">{{ step.index + 1 }}</p>
      <img
        *ngIf="activeIndex > step.index"
        src="assets/icons/check.svg"
        alt="check"
        class="check"
        width="20"
        height="20"
      />
      <dot-separator class="mx-2"></dot-separator>
      <p [ngClass]="{ 'text-jazlyn-700': activeIndex > step.index }">{{ step.step }}</p>
    </div>
  </div>

  <div *ngIf="!expanded" class="d-flex align-items-center">
    <p>
      {{ (activeIndex + 1) > steps.length ? steps.length : activeIndex + 1 }} of {{ steps.length }}
    </p>
    <dot-separator class="mr-2 ml-1"></dot-separator>
    <p>{{ steps[activeIndex] ? steps[activeIndex].step : steps[steps.length - 1].step }}</p>
  </div>

  <img
    [ngClass]="{ 'rotated': expanded }"
    src="assets/icons/chevron_down.svg"
    alt="chevron"
    class="chevron"
  />
</div>

<div class="d-none d-md-flex align-items-center">
  <div *ngFor="let step of steps; let first = first" class="d-flex align-items-center">
    <div *ngIf="!first" class="mx-4 separator"></div>

    <div
      *ngIf="activeIndex <= step.index"
      [ngClass]="{ 'active-step': activeIndex === step.index }"
      class="step"
    >
      <p [ngClass]="{ 'text-jazlyn-700': activeIndex !== step.index }" class="caption">
        {{ step.index + 1 }}
      </p>
    </div>

    <div *ngIf="activeIndex > step.index" class="step done-step"></div>

    <p [ngClass]="{ 'text-jazlyn-700': activeIndex !== step.index }" class="ml-2">
      {{ step.step }}
    </p>
  </div>
</div>
