import { Pipe, PipeTransform } from '@angular/core';
import { from, map } from 'rxjs';

import { Option } from '@app/forms/formly/formly-utils';

@Pipe({
  name: 'getCountryById',
})
export class GetCountryByIdPipe implements PipeTransform {
  transform(countryId: Option['value']) {
    return from(import('static/countries').then((mod) => mod.default as unknown as Option[])).pipe(
      map((options) => [...options].find(({ value }) => value === countryId))
    );
  }
}
