import { toLabel } from '@app/ui/pipes/label.pipe';

export function convertEnumToOptions<T extends string>(e: Record<string, T>): Option[] {
  return Object.values(e).map((value) => ({ value, label: toLabel(value) }));
}

export type Option<T = string> = {
  value: T;
  label: string;
  extra?: { [key: string]: unknown };
};

export type Formdata<T = any> = T;
