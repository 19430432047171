<div class="d-flex align-items-center">
  <div class="wrapper mr-4">
    <div class="dot p-relative"></div>
  </div>

  <img src="assets/icons/star-blue.svg" width="20" height="20" alt="info" class="mb-auto mb-md-0" />

  <p class="ml-2 semi-bold text-bleu-de-france-600">Hidden City Ticketing</p>
</div>

<div class="d-flex mt-2">
  <div class="wrapper mr-4"></div>

  <p class="text-jazlyn-700">
    This itinerary finishes in <span data-id="to">{{ itinerary.arrivalAirport.cityName }}</span> ({{
    itinerary.arrivalAirport.locationCode }}), but you’ll get off during the layover.
    <br class="d-md-none" />
    <a
      (click)="$event.stopPropagation(); show(modalTemplate)"
      class="link underline text-bleu-de-france-600 d-flex d-md-inline mt-2 mt-md-0"
      data-id="more"
      >Learn more</a
    >
  </p>
</div>

<ng-template #modalTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-column flex-md-row align-items-md-center p-4 p-md-6 header">
      <p (click)="hide()" class="d-md-none d-flex align-items-center mb-4" data-id="hide">
        <img src="assets/icons/chevron_left.svg" alt="chevron" />
        <a class="link text-jazlyn-700 ml-1">Back</a>
      </p>

      <h2 class="semi-bold">
        Explore Hidden City Ticketing for Potential<br class="d-none d-md-inline" />
        Fare Savings
      </h2>

      <img
        (click)="hide()"
        src="assets/icons/close.svg"
        width="20"
        height="20"
        class="d-none d-md-inline ml-auto cursor-pointer"
        alt="close"
        data-id="hide"
      />
    </div>

    <div class="p-4 p-md-6">
      <div class="py-4 py-md-6 d-flex flex-column align-items-center info">
        <p class="caption semi-bold text-sun-crete-600">
          This is your destination. You should get off here
        </p>

        <img
          src="assets/icons/arrow_down-orange.svg"
          width="24"
          height="24"
          alt="arrow"
          class="mt-1"
        />

        <div class="d-flex flex-column">
          <img
            src="assets/images/Itinerary.svg"
            width="236"
            height="35"
            class="mx-2"
            alt="itinerary"
          />

          <div class="d-flex align-items-center justify-content-between">
            <p class="semi-bold" data-id="from">{{ itinerary.departureAirport.locationCode }}</p>
            <p class="semi-bold" data-id="to">
              {{ itinerary.finalDestinationAirport.locationCode }}
            </p>
            <p class="semi-bold text-jazlyn-600" data-id="extra">
              {{ itinerary.arrivalAirport.locationCode }}
            </p>
          </div>
        </div>
      </div>

      <p class="mt-4 mt-md-6">
        Hidden City Ticketing can be a smart way to find lower airfares. This<br
          class="d-none d-md-inline"
        />
        trick involves booking a flight with a stopover at your actual destination,<br
          class="d-none d-md-inline"
        />
        where you disembark.
      </p>

      <p class="mt-4">Key Points to Consider:</p>

      <div class="mt-3 d-flex flex-column">
        <div class="d-flex">
          <span class="quotes-dot mr-2"></span>
          <p>Suitable for one-way trips.</p>
        </div>

        <div class="d-flex mt-3">
          <span class="quotes-dot mr-2"></span>
          <p>
            If you have checked luggage, ensure<br class="d-md-none" />
            it’s checked only to the layover<br class="d-none d-md-inline" />
            city<br class="d-md-none" />
            where you plan to disembark.
          </p>
        </div>

        <div class="d-flex mt-3">
          <span class="quotes-dot mr-2"></span>
          <p>
            A practical choice for experienced travelers looking for cost-effective<br
              class="d-none d-md-inline"
            />
            options.
          </p>
        </div>
      </div>

      <div class="mt-4 px-4 py-3 d-flex flex-column note">
        <div class="d-flex align-items-center">
          <img src="assets/icons/error-blue.svg" width="20" height="20" alt="note" />
          <p class="semi-bold ml-1 text-travalign-brand-blue">Note</p>
        </div>

        <p class="mt-1">
          This method is less conventional and not typically promoted<br
            class="d-none d-md-inline"
          />
          by airlines. Use it with awareness of the possible implications.
        </p>
      </div>
    </div>
  </div>
</ng-template>
