<div class="d-flex justify-content-center mt-10">
  <img src="assets/images/bcc_logo.svg" alt="BCC" width="124" height="32" />
</div>

<div class="d-flex justify-content-center mt-10 pt-4">
  <img src="assets/images/planet-bw.svg" alt="planet" width="86" height="86" />
</div>

<div class="d-flex flex-column align-items-center mt-4">
  <h3 class="semi-bold">404</h3>
  <p>Page not found</p>
</div>
