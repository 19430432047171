import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  firstOpen = true;

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event']) click(event: MouseEvent) {
    const element = this.elementRef?.nativeElement as HTMLElement;
    const needsHide = !element?.contains(event.target as HTMLElement) && !this.firstOpen;

    if (needsHide) {
      this.clickOutside.emit(event);
    } else if (this.firstOpen) {
      this.firstOpen = false;
    }
  }

  constructor(protected elementRef: ElementRef) {}
}
