import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalHotelBooking } from '@app/services/api/api.types';

@Component({
  selector: 'hotel-bookings',
  templateUrl: './hotel-bookings.component.html',
  styleUrls: ['./hotel-bookings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelBokingsComponent {
  @Input() hotelBookings: PortalHotelBooking[];
}
