<card class="flex-column p-4">
  <div class="d-flex align-items-center">
    <img src="assets/icons/local_police.svg" alt="local_police" width="24" height="24" />

    <h2 class="semi-bold text-green-700 ml-1">Premium Disruption Assistance</h2>
  </div>

  <h3 class="semi-bold mt-2">
    Protect your travel plans from carrier-initiated changes and cancellations.
  </h3>

  <p class="mt-2">
    Unexpected airline changes, like major schedule shifts or last-minute cancellations due to
    weather or crew issues, can really throw off your vacation or business trip. Premium Disruption
    Assistance keeps you covered. With this protection, you can easily access the best alternative
    flights or get quick refunds, ensuring you reach your destination with minimal hassle.
  </p>

  <p class="mt-1">
    <a
      (click)="$event.stopPropagation()"
      href="https://businessclassconsolidator.com/protection-plans"
      target="_blank"
      class="link text-bleu-de-france-600 d-flex"
      >Learn more<img src="assets/icons/external_link.svg" alt="external_link" class="ml-1"
    /></a>
  </p>

  <div class="d-flex flex-column-reverse flex-md-row mt-4">
    <card
      (click)="selectProtection.emit(0)"
      [ngClass]="{ 'selected':  disruptionProtection === 0 }"
      class="mt-2 mt-md-0 flex-column flex-1 cursor-pointer points"
      data-id="points"
    >
      <ng-container *ngTemplateOutlet="head"></ng-container>
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </card>

    <card
      (click)="selectProtection.emit(option.disruptionProtectionAmount)"
      [ngClass]="{ 'selected':  disruptionProtection === option.disruptionProtectionAmount }"
      class="ml-md-2 flex-column flex-1 cursor-pointer protection-points"
      data-id="protection-points"
    >
      <ng-container *ngTemplateOutlet="protectionHead"></ng-container>
      <ng-container *ngTemplateOutlet="protectionPoints"></ng-container>
    </card>
  </div>
</card>

<ng-template #head>
  <div class="d-flex flex-column p-3 head">
    <div class="d-flex align-items-start nowrap">
      <img src="assets/icons/remove_moderator.svg" alt="remove_moderator" width="20" height="20" />

      <p class="ml-1">No, I understand<br />the risk</p>

      <radio [selected]="disruptionProtection === 0" class="ml-auto"></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold">{{ 0 | portalCurrency }}</h3>
    </div>
  </div>
</ng-template>

<ng-template #protectionHead>
  <div class="d-flex flex-column p-3 protection-head">
    <div class="d-flex align-items-start nowrap">
      <img src="assets/icons/local_police.svg" alt="local_police" width="20" height="20" />

      <p class="ml-1">Yes, protect my trip<br />&nbsp;</p>

      <radio
        [selected]="disruptionProtection === option.disruptionProtectionAmount"
        class="ml-auto"
      ></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold" data-id="per-traveler-amount">
        {{ option.disruptionProtectionAmountPerPaidPassenger | portalCurrency }}
      </h3>

      <p>/traveler</p>

      <p class="text-jazlyn-100 caption badge">Recommended</p>
    </div>
  </div>
</ng-template>

<ng-template #points>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">No rebooking coverage</p>
      </div>

      <p class="ml-6 mt-1">
        If your itinerary gets disrupted, and you need to book new flights, you will have to cover
        the entire cost of the new flights yourself.
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Carrier-Provided Alternatives</p>
      </div>

      <p class="ml-6 mt-1">Manage self-transfers and multiple bookings without our support.</p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Delayed refunds</p>
      </div>

      <p class="ml-6 mt-1">
        Refunds processed by the airline can be delayed and the process may be unclear.
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Self-Transfer and Multiple Bookings</p>
      </div>

      <p class="ml-6 mt-1">
        Choose from options provided by the airline only.<br class="d-none d-md-inline" /><br
          class="d-none d-md-inline"
        /><br class="d-none d-md-inline" />
      </p>
    </div>

    <hr class="my-3 protection-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Limited support hours</p>
      </div>

      <p class="ml-6 mt-1">Assistance available from 9am–6pm EST.</p>
    </div>
  </div>
</ng-template>

<ng-template #protectionPoints>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Rebooking coverage</p>
      </div>

      <p class="ml-6 mt-1">
        We cover rebooking costs up to the original ticket price plus an additional $1,000 to handle
        disruptions smoothly.
        <br class="d-none d-md-inline" /><br class="d-none d-md-inline" />
      </p>
    </div>

    <hr class="my-3 protection-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">
          Alternative Flight<br class="d-none d-md-inline" />
          Flexibility
        </p>
      </div>

      <p class="ml-6 mt-1">We’ll find and cover rebooking costs up to the original ticket price.</p>
    </div>

    <hr class="my-3 protection-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Instant Refunds</p>
      </div>

      <p class="ml-6 mt-1">
        Receive an instant refund in travel credit or cash within 7-10 business days.
      </p>
    </div>

    <hr class="my-3 protection-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">End-to-End Itinerary Assistance</p>
      </div>

      <p class="ml-6 mt-1">
        We’ll rearrange your entire itinerary for trips with multiple bookings to ensure minimal
        disruption.
      </p>
    </div>

    <hr class="my-3 protection-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">24/7 Support</p>
      </div>

      <p class="ml-6 mt-1">Get round-the-clock help for any travel disruptions.</p>
    </div>
  </div>
</ng-template>
