import { Option } from '@app/forms/formly/formly-utils';
import {
  PortalCreditCardNode,
  PortalPassengerNode,
  PortalFlightItineraryGroupNode,
} from '@app/services/api/api.types';
import { toLabel } from '@app/ui/pipes/label.pipe';

export const scrollTo = (el: Element | string) => {
  setTimeout(() => {
    if (typeof el === 'string') {
      el = document.querySelector(el) as Element;
    }

    el?.scrollIntoView({ behavior: 'smooth' });
  });
};

export const beforeUnloadHandler = (event: Event) => {
  event.preventDefault();
  event.returnValue = true;
};

export const getMixedClassesState = (itinerary: PortalFlightItineraryGroupNode) => {
  const classes = new Set();
  itinerary.segments.forEach((segment) => classes.add(segment.flightSegment.serviceClass));
  return classes.size > 1;
};

export const getLongestSegment = (itinerary: PortalFlightItineraryGroupNode) => {
  return itinerary.segments.find((segment) => segment.longest) || itinerary.segments[0];
};

export const setMaxHeight = () => {
  const vh = Math.floor(window.innerHeight * 0.01 * 100);
  document.documentElement.style.setProperty('--mobile-modal-height', `calc(${vh}px - 32px)`);
};

export const mapPortalPassengerNode = (p: PortalPassengerNode) => {
  return {
    value: p.id,
    label: p.fullName,
    extra: {
      firstName: p.firstName,
      middleName: p.middleName,
      lastName: p.lastName,
      birthday: p.birthday,
      gender: p.gender,
      passportCountryId: p.countryId,
    },
  } as Option;
};

export const mapPortalCreditCardNode = (c: PortalCreditCardNode) => {
  return {
    value: c.id,
    label: `${toLabel(c.type)} **** ${c.number.slice(-4)}`,
    extra: {
      holder: c.holder,
      expiration: c.expiration,
    },
  } as Option;
};

export const getDomain = (email = '') => {
  const regex = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const match = email.match(regex) as string[];

  if (match) {
    const domain = match[2].toLowerCase();

    if (domain.includes('gmail.com')) {
      return { label: 'Gmail', url: 'https://gmail.com' };
    } else if (domain.includes('yahoo.com') || domain.includes('myyahoo.com')) {
      return { label: 'Yahoo', url: 'https://mail.yahoo.com' };
    } else if (
      domain.includes('outlook.com') ||
      domain.includes('hotmail.com') ||
      domain.includes('live.com') ||
      domain.includes('msn.com')
    ) {
      return { label: 'Outlook', url: 'https://outlook.live.com' };
    }
  }

  return null;
};
