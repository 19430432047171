import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map } from 'rxjs';

import {
  authorizedData,
  portalAcceptVerification,
  portalLead,
  portalOptionSelected,
  portalSavePassengersToClient,
  portalSendVerificationEmail,
  portalSetHiddenFlightsPresented,
  portalSubmitOption,
  portalUpdateOption,
  sendPasswordToEmail,
} from '@app/services/api/api.graphql';
import {
  AuthorizedDataNode,
  PortalPassengerNodeInput,
  PortalLeadNode,
  PortalOrderNode,
  PortalOrderStatusType,
  PortalCreditCardNodeInput,
  PortalPassengerNode,
} from '@app/services/api/api.types';
import { Option } from '@app/forms/formly/formly-utils';
import { AuthService } from '@app/services/auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  get headers() {
    const user = this.authService.user;

    if (user?.token) {
      return { Authorization: `Bearer ${user.token}` };
    }

    return {};
  }

  portalLead(variables: { portalLink: string }) {
    const query = portalLead();

    return this.httpClient
      .post<{ data: { portalLead: PortalLeadNode }; errors: Error[] }>(baseUrl, {
        query,
        variables,
      })
      .pipe(
        map((response) => ({
          portalLead: response.data.portalLead,
          errors: response.errors,
        }))
      );
  }

  portalUpdateOption(variables: {
    inputUpdate: {
      portalLink: string;
      status: PortalOrderStatusType;
    };
  }) {
    const query = portalUpdateOption();

    return this.httpClient
      .post<{ data: { portalUpdateOption: { ok: boolean } } }>(baseUrl, { query, variables })
      .pipe(map((response) => response.data.portalUpdateOption));
  }

  portalSubmitOption(variables: {
    input: {
      portalLink: string;
      passengers: PortalPassengerNodeInput[];
      creditCard?: PortalCreditCardNodeInput;
      tipsAmount?: number;
      disruptionProtection?: boolean;
      cancelForAnyReason?: boolean;
    };
  }) {
    const query = portalSubmitOption();

    return this.httpClient
      .post<{ data: { portalSubmitOption: { ok: boolean; errors: string } } }>(cloudfrontApi, {
        query,
        variables,
      })
      .pipe(map((response) => response.data.portalSubmitOption));
  }

  countries() {
    return from(import('static/countries').then((mod) => mod.default as unknown as Option[])).pipe(
      map((options) => [...options])
    );
  }

  states() {
    return from(import('static/states').then((mod) => mod.default as unknown as Option[])).pipe(
      map((options) => [...options])
    );
  }

  portalSavePassengersToClient(variables: {
    input: { portalLink: string; passengers: PortalPassengerNodeInput[] };
  }) {
    const query = portalSavePassengersToClient();

    return this.httpClient
      .post<{
        data: {
          portalSavePassengersToClient: {
            ok: boolean;
            errors: string;
            passengers: PortalPassengerNode[];
          };
        };
      }>(baseUrl, {
        query,
        variables,
      })
      .pipe(map((response) => response.data.portalSavePassengersToClient));
  }

  portalSetHiddenFlightsPresented(variables: { portalLink: string }) {
    const query = portalSetHiddenFlightsPresented();

    return this.httpClient
      .post<{
        data: { portalSetHiddenFlightsPresented: { ok: boolean; result: PortalOrderNode } };
      }>(baseUrl, { query, variables })
      .pipe(map((response) => response.data.portalSetHiddenFlightsPresented));
  }

  authorizedData(variables: { portalLink: string }) {
    const query = authorizedData();

    const headers = this.headers as HttpHeaders;

    return this.httpClient
      .post<{ data: { authorizedData: AuthorizedDataNode } }>(
        protectedBaseUrl,
        { query, variables },
        { headers }
      )
      .pipe(map((response) => response.data.authorizedData));
  }

  portalSendVerificationEmail(variables: { portalLink: string }) {
    const query = portalSendVerificationEmail();

    const headers = this.headers as HttpHeaders;

    return this.httpClient
      .post<{ data: { portalSendVerificationEmail: { ok: boolean; result: AuthorizedDataNode } } }>(
        protectedBaseUrl,
        { query, variables },
        { headers }
      )
      .pipe(map((response) => response.data.portalSendVerificationEmail));
  }

  portalAcceptVerification(variables: { portalLink: string; verificationCode: string }) {
    const query = portalAcceptVerification();

    const headers = this.headers as HttpHeaders;

    return this.httpClient.post<{
      data: { portalAcceptVerification: { ok: boolean; result: AuthorizedDataNode } };
      errors: { message: string }[];
    }>(protectedBaseUrl, { query, variables }, { headers });
  }

  sendPasswordToEmail(variables: { portalLink: string; email: string }) {
    const query = sendPasswordToEmail();

    return this.httpClient.post<{
      data: { sendPasswordToEmail: { ok: boolean } };
      errors: Error[];
    }>(baseUrl, { query, variables });
  }

  portalOptionSelected(variables: { portalLink: string }) {
    const query = portalOptionSelected();

    return this.httpClient.post<{
      data: { portalOptionSelected: { ok: boolean } };
    }>(baseUrl, { query, variables });
  }
}

const baseUrl = environment.GRAPHQL_URL;
const protectedBaseUrl = environment.GRAPHQL_PROTECTED_URL as string;
const cloudfrontApi = environment.CLOUDFRONT_API as string;
