<ng-container *ngFor="let itinerary of option.flightItineraries">
  <ng-container
    *ngIf="itinerary.finalDestinationAirport && itinerary.finalDestinationAirport?.locationCode !== itinerary.arrivalAirport.locationCode"
  >
    <p class="semi-bold mb-2">Hidden City Ticketing</p>
    <p>
      This itinerary finishes in
      <span data-id="to">{{ itinerary.arrivalAirport.cityName }}</span> ({{
      itinerary.arrivalAirport.locationCode }}), but you’ll get off during the layover
    </p>
  </ng-container>
</ng-container>
