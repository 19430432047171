import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from '@app/app.module';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

declare global {
  interface Window {
    makeClientTracker: (api: string, load: boolean) => any[];
  }
}

if (environment.production) {
  Sentry.init({
    // TODO release: environment.RELEASE,
    dsn: 'https://0bb7a4d7108e4f119363f8bb532b5685@o1146485.ingest.sentry.io/4504491483660288',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.2,
    ignoreErrors: ['Non-Error promise rejection captured'],
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

const appendTrackerScript = (trackingUrl = '') => {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `${trackingUrl}/assets/tracker.js`;
  script.onload = () => {
    if (typeof window.makeClientTracker === 'function') {
      const clientTrackerApi = `${trackingUrl}/images/ct.gif`;

      const clientTracker = window.makeClientTracker(clientTrackerApi, true) || [];

      window.track = function (meta) {
        clientTracker.push(meta);
      };

      const lead_portal_link = document.location.pathname.substring(1);

      window.track({ event_name: 'quote_opened', lead_portal_link });

      window.addEventListener('beforeunload', function () {
        window.track({ event_name: 'quote_closed', lead_portal_link });
      });
    }
  };

  document.body.appendChild(script);
};

appendTrackerScript(environment.TRACKING_URL as string);
