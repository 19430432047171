<ng-container *ngFor="let itinerary of option.flightItineraries">
  <ng-container *ngIf="!itinerary.segments.length">
    <p class="semi-bold mb-2">Throwaway Ticketing</p>
    <p>
      You’ll get return ticket
      <span data-id="from">{{ itinerary.departureAirport.cityName }}</span>
      –
      <span data-id="to">{{ itinerary.arrivalAirport.cityName }}</span>
      because this flight is cheaper as a round-trip ticket, but you’re free to use only the flights
      you need
    </p>
  </ng-container>
</ng-container>
