<div class="d-flex align-items-center justify-content-between px-4 pt-4 px-md-8 pt-md-8">
  <h1 class="semi-bold">Want to sign in first?</h1>

  <img
    (click)="hideModal.emit()"
    src="assets/icons/close.svg"
    alt="close"
    class="cursor-pointer d-none d-md-inline"
    data-id="hide"
  />
</div>

<p class="mt-2 mx-4 mx-md-8">You'll be able to book faster and see all your trips in one place.</p>

<div class="d-flex flex-column mx-4 mx-md-8 mb-4 mb-mx-8">
  <button (click)="showSignInModal.emit()" class="button mt-8" data-id="sign-in">Sign in</button>

  <button
    (click)="continueAsGuest.emit()"
    class="button secondary mt-4"
    data-id="continue-as-a-guest"
  >
    Continue as a guest
  </button>
</div>
