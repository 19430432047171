<flight-info [portalLead]="portalLead" class="mx-4 mx-md-0"></flight-info>

<stepper [step]="Steps.MeetYourExpert" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

<ng-container *ngIf="portalLead.salesAgent">
  <card class="mt-6 mt-md-8 flex-column align-items-center agent">
    <img [src]="portalLead.salesAgent.picture" alt="agent" class="picture" />

    <p class="mt-6 text-align-center">
      <b data-id="fullname">{{ portalLead.salesAgent.fullName }}</b>, your Travel Expert, will reach
      out soon to discuss your trip<br class="d-none d-md-inline" />
      and savings. Giving {{ portalLead.salesAgent.fullName | getFirstName }} your trip details will
      help us save you money.
    </p>

    <div class="d-flex justify-content-center flex-wrap mt-2">
      <div class="px-2 py-1 badge m-1">
        <p data-id="deals">{{ portalLead.salesAgent.deals }} planned trips</p>
      </div>

      <div class="px-2 py-1 badge m-1">
        <p data-id="avg-savings">
          Saves clients {{ portalLead.salesAgent.avgSavings | portalCurrency }} on average
        </p>
      </div>

      <div class="px-2 py-1 badge m-1">
        <p>97% client happiness score</p>
      </div>
    </div>
  </card>

  <reviews [salesAgent]="portalLead.salesAgent" class="my-4 my-md-8 mx-4 mx-md-0"></reviews>
</ng-container>
