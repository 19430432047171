import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'label',
})
export class LabelPipe implements PipeTransform {
  transform(value = '') {
    return toLabel(value);
  }
}

export const toLabel = (value: string) => {
  return (value || '')
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
