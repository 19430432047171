<div class="d-flex flex-column align-items-center pt-10 pb-4 pb-md-10">
  <h2 class="d-none d-md-inline semi-bold text-align-center">
    Are you sure you want to go back and<br />discard the entered information?
  </h2>

  <h3 class="d-md-none semi-bold text-align-center">
    Are you sure you want to go back<br />and discard the entered information?
  </h3>

  <p class="my-3 text-align-center">
    This information cannot be restored once<br class="d-md-none" />
    it's gone.
  </p>

  <div
    class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center mt-10 px-4 w-100"
  >
    <button
      (click)="hide()"
      class="button mt-2 mt-md-0 secondary w-100 w-md-unset"
      data-id="cancel"
    >
      Cancel
    </button>
    <button (click)="goBack()" class="button ml-md-6 w-100 w-md-unset" data-id="back">
      Go back and discard
    </button>
  </div>
</div>
