import { Injectable } from '@angular/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AwsAmplifyService {
  federatedSignIn(provider: CognitoHostedUIIdentityProvider, customState?: string) {
    return Auth.federatedSignIn({ provider, customState });
  }

  signIn(username: string, password: string) {
    return Auth.signIn({ username, password });
  }

  currentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }

  currentSession() {
    return Auth.currentSession();
  }

  signOut() {
    return Auth.signOut({ global: true });
  }
}
