import { Pipe, PipeTransform } from '@angular/core';

import { TotalTravelTimeNode } from '@app/services/api/api.types';

@Pipe({
  name: 'travelTime',
})
export class TravelTimePipe implements PipeTransform {
  transform(value: TotalTravelTimeNode) {
    const result = [];

    if (value) {
      if (value.days && value.days > 0) {
        result.push(`${value.days}d`);
      }
      if (value.hours && value.hours > 0) {
        result.push(`${value.hours}h`);
      }
      if (value.minutes && value.minutes > 0) {
        result.push(`${value.minutes}m`);
      }
    }

    return result.join(' ');
  }
}
