import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractNodes',
})
export class ExtractNodesPipe implements PipeTransform {
  transform<T = any>(edges: { node: T }[] = []): T[] {
    return edges?.map((edge) => edge.node);
  }
}
