<card class="flex-column p-4">
  <div class="d-flex align-items-center">
    <img src="assets/icons/diamond.svg" alt="diamond" width="24" height="24" />

    <h2 class="semi-bold text-green-700 ml-1">Cancel For Any Reason</h2>
  </div>

  <h3 class="semi-bold mt-1">
    Cancel for any reason flexibility and peace of mind for your travel plans.
  </h3>

  <p class="mt-1">
    Experience ultimate flexibility with our Cancel For Any Reason (CFAR). Whether your plans change
    unexpectedly or you simply decide not to travel, our CFAR option ensures you're covered.
  </p>

  <p class="mt-1">
    <a
      (click)="$event.stopPropagation()"
      href="https://businessclassconsolidator.com/terms/"
      target="_blank"
      class="link text-bleu-de-france-600 d-flex"
      >Details & terms<img src="assets/icons/external_link.svg" alt="external_link" class="ml-1"
    /></a>
  </p>

  <div class="d-flex d-md-none flex-column mt-4">
    <card
      (click)="selectCancelForAnyReason.emit(option.cancelForAnyReasonAmount)"
      [ngClass]="{ 'selected': cancelForAnyReason === option.cancelForAnyReasonAmount }"
      class="flex-column cfar-points"
      data-id="select-cfar"
    >
      <ng-container *ngTemplateOutlet="cfarHead"></ng-container>
      <ng-container *ngTemplateOutlet="cfarPoints"></ng-container>
    </card>

    <card
      (click)="selectCancelForAnyReason.emit(0)"
      [ngClass]="{ 'selected': cancelForAnyReason === 0 }"
      class="flex-column points mt-2"
      data-id="unselect-cfar"
    >
      <ng-container *ngTemplateOutlet="head"></ng-container>
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </card>
  </div>

  <div class="d-none d-md-flex flex-column mt-4">
    <card *ngIf="cancelForAnyReason !== 0" class="cfar-points">
      <ng-container *ngTemplateOutlet="cfarPoints"></ng-container>
    </card>

    <card *ngIf="cancelForAnyReason === 0" class="points">
      <ng-container *ngTemplateOutlet="points"></ng-container>
    </card>

    <card
      (click)="selectCancelForAnyReason.emit(option.cancelForAnyReasonAmount)"
      [ngClass]="{ 'selected': cancelForAnyReason === option.cancelForAnyReasonAmount }"
      class="cursor-pointer mt-2 cfar-points"
      data-id="select-cfar"
    >
      <ng-container *ngTemplateOutlet="cfarHead"></ng-container>
    </card>

    <card
      (click)="selectCancelForAnyReason.emit(0)"
      [ngClass]="{ 'selected': cancelForAnyReason === 0 }"
      class="cursor-pointer mt-2 points"
      data-id="unselect-cfar"
    >
      <ng-container *ngTemplateOutlet="head"></ng-container>
    </card>
  </div>
</card>

<ng-template #cfarHead>
  <div class="d-flex flex-column p-3 w-100 cfar-head">
    <div class="d-flex align-items-start nowrap">
      <img src="assets/icons/diamond.svg" alt="diamond" width="20" height="20" />

      <p class="ml-1">I want CFAR</p>

      <p class="ml-4 text-jazlyn-100 caption badge">Recommended</p>

      <radio
        [selected]="cancelForAnyReason === option.cancelForAnyReasonAmount"
        class="ml-auto"
      ></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold" data-id="per-traveler-amount">
        {{ option.cancelForAnyReasonAmountPerPaidPassenger | portalCurrency }}
      </h3>

      <p>/traveler</p>
    </div>
  </div>
</ng-template>

<ng-template #cfarPoints>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Flexible Cancellations</p>
      </div>

      <p class="ml-6 mt-1">
        Cancel your trip for any reason up to 48 hours before departure and receive a refund.
      </p>
    </div>

    <hr class="my-3 cfar-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">Generous Refund Options</p>
      </div>

      <p class="ml-6 mt-1">
        Receive 80% (<b data-id="cancel-for-any-reason-refund-per-traveler"
          >{{ option.cancelForAnyReasonRefundPerTraveler | portalCurrency }}</b
        >/per traveler) cash refund or 100% (<b
          data-id="cancel-for-any-reason-balance-refund-per-traveler"
          >{{ option.cancelForAnyReasonBalanceRefundPerTraveler | portalCurrency }}</b
        >/per traveler) as a travel credit.
      </p>
    </div>

    <hr class="my-3 cfar-points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/check_600.svg" alt="check_600" width="20" height="20" />

        <p class="semi-bold ml-1">No Cancellation Fees</p>
      </div>

      <p class="ml-6 mt-1">The cancellation fee is waived, making the process hassle-free.</p>
    </div>
  </div>
</ng-template>

<ng-template #head>
  <div class="d-flex flex-column p-3 w-100 head">
    <div class="d-flex align-items-start nowrap">
      <img
        src="assets/icons/diamond_crossed_out.svg"
        alt="diamond_crossed_out"
        width="20"
        height="20"
      />

      <p class="ml-1">No thanks, I decline flight flexibility</p>

      <radio [selected]="cancelForAnyReason === 0" class="ml-auto"></radio>
    </div>

    <div class="d-flex mt-2 ml-6">
      <h3 class="semi-bold">{{ 0 | portalCurrency }}</h3>
    </div>
  </div>
</ng-template>

<ng-template #points>
  <div class="d-flex flex-column p-3">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">No Flexibility</p>
      </div>

      <p class="ml-6 mt-1">
        You won’t have the option to cancel your trip for any reason and receive a refund
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Standard Policies Apply</p>
      </div>

      <p class="ml-6 mt-1">
        Changes and cancellations will follow standard airline policies, which may include fees
      </p>
    </div>

    <hr class="my-3 points-separator" />

    <div class="d-flex flex-column">
      <div class="d-flex">
        <img src="assets/icons/close_600.svg" alt="close_600" width="20" height="20" />

        <p class="semi-bold ml-1">Limited Refund Options</p>
      </div>

      <p class="ml-6 mt-1">Refunds may be limited and subject to airline terms and conditions.</p>
    </div>
  </div>
</ng-template>
