<ng-container *ngIf="portalLead$ | async as portalLead">
  <flight-info [portalOrder]="portalOrder" class="mx-4 mx-md-0"></flight-info>

  <stepper
    [step]="Steps.TravelerDetails"
    [withPayAndConfirm]="withPayAndConfirm"
    class="mt-3 mt-md-4 mx-4 mx-md-0"
  ></stepper>

  <div class="my-4 my-md-8 d-flex flex-column flex-md-row">
    <div class="flex-1">
      <card class="flex-column p-4">
        <card class="flex-column p-4 card">
          <img src="assets/images/examples.png" alt="examples" class="img" />

          <h2 class="semi-bold">Express Your Gratitude</h2>

          <p class="mt-2">
            We're excited to have found you the perfect itinerary. Our team
            <br class="d-none d-md-inline" />works hard to deliver the best value. If you appreciate
            our efforts, <br class="d-none d-md-inline" />consider showing your gratitude.
          </p>
        </card>

        <div class="d-flex flex-column mt-4">
          <div class="d-flex">
            <button
              (click)="selectTipsAmount(0)"
              [ngClass]="{ 'pressed': tipsAmount === 0 }"
              class="button secondary flex-1"
              data-id="not-now"
            >
              Not now
            </button>
            <button
              (click)="selectTipsAmount(150)"
              [ngClass]="{ 'pressed': tipsAmount === 150 }"
              class="button secondary flex-1 ml-2"
              data-id="150"
            >
              {{ 150 | portalCurrency }}
            </button>
            <button
              (click)="selectTipsAmount(200)"
              [ngClass]="{ 'pressed': tipsAmount === 200 }"
              class="button secondary flex-1 ml-2"
              data-id="200"
            >
              {{ 200 | portalCurrency }}
            </button>
          </div>

          <div class="d-flex mt-2">
            <button
              (click)="selectTipsAmount(250)"
              [ngClass]="{ 'pressed': tipsAmount === 250 }"
              class="button secondary flex-1"
              data-id="250"
            >
              {{ 250 | portalCurrency }}
            </button>
            <button
              (click)="selectTipsAmount(300)"
              [ngClass]="{ 'pressed': tipsAmount === 300 }"
              class="button secondary flex-1 ml-2"
              data-id="300"
            >
              {{ 300 | portalCurrency }}
            </button>
            <button
              (click)="selectTipsAmount(350)"
              [ngClass]="{ 'pressed': tipsAmount === 350 }"
              class="button secondary flex-1 ml-2"
              data-id="350"
            >
              {{ 350 | portalCurrency }}
            </button>
          </div>
        </div>
      </card>

      <div class="d-none d-md-flex mt-6">
        <button
          (click)="tryBack()"
          class="button secondary d-flex align-items-center"
          data-id="back"
        >
          <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
          Back
        </button>

        <button
          (click)="submit()"
          class="button ml-auto d-flex align-items-center"
          data-id="submit"
        >
          Continue
          <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
        </button>
      </div>
    </div>

    <div class="d-none d-md-block ml-md-4 summary">
      <h2 class="semi-bold mb-4">Price summary</h2>

      <price-summary
        [option]="portalOrder"
        [disruptionProtection]="state.disruptionProtection"
        [tipsAmount]="tipsAmount"
        [cancelForAnyReason]="state.cancelForAnyReason"
      ></price-summary>
    </div>

    <div class="d-flex flex-column d-md-none mt-4 mx-4">
      <button
        (click)="submit()"
        data-id="submit"
        class="button d-flex align-items-center justify-content-center"
      >
        Continue
        <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
      </button>

      <button
        (click)="tryBack()"
        class="button secondary mt-4 d-flex align-items-center justify-content-center"
        data-id="back"
      >
        <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
        Back
      </button>
    </div>
  </div>
</ng-container>

<ng-template #confirmTemplate>
  <go-back-confirm (back)="back()"></go-back-confirm>
</ng-template>
