import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasDaysShift',
})
export class HasDaysShiftPipe implements PipeTransform {
  transform(daysShift: number) {
    return daysShift && (daysShift > 0 || daysShift < 0);
  }
}
