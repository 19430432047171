import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInModalComponent {
  @Output() hideModal = new EventEmitter();
  @Output() continueWithGoogle = new EventEmitter();
  @Output() continueWithFacebook = new EventEmitter();
  @Output() continueWithEmail = new EventEmitter();
}
