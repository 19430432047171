import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ValidationErrors } from '@angular/forms';

import { Option } from '@app/forms/formly/formly-utils';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnDestroy {
  dropdownSeparatorValue = dropdownSeparatorValue;

  options$: Subscription;

  cachedOptions: Option[];

  @Input() value?: unknown;

  @Input() placeholder?: string;
  @Input() disabled: boolean;
  @Input() touched: boolean;
  @Input() errors: ValidationErrors | null;

  @Input() options: Observable<Option[]>;

  @Output() markAsTouched = new EventEmitter();
  @Output() selectOption = new EventEmitter<Option['value']>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.options$ = this.options.subscribe((options) => {
      this.cachedOptions = options;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.options$?.unsubscribe();
  }
}

export const dropdownSeparatorValue = 'separator';
