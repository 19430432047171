import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMs',
})
export class FormatMsPipe implements PipeTransform {
  transform(milliseconds: number): string {
    if (milliseconds < 0) return '0m 0s';

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}m ${seconds}s`;
  }
}
