import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalPassengerNodeInput } from '@app/services/api/api.types';

@Component({
  selector: 'passengers-preview',
  templateUrl: './passengers-preview.component.html',
  styleUrls: ['./passengers-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengersPreviewComponent {
  @Input() passengers: PortalPassengerNodeInput[];
}
