import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { PopupsService } from '@app/ui/services/popups.service';

@Component({
  selector: 'go-back-confirm',
  templateUrl: './go-back-confirm.component.html',
  styleUrls: ['./go-back-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoBackConfirmComponent {
  @Output() back = new EventEmitter();

  constructor(private popupsService: PopupsService) {}

  goBack() {
    this.back.emit();
    this.hide();
  }

  hide() {
    this.popupsService.hideLast();
  }
}
