import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() content: TemplateRef<unknown>;
  @Input() componentRef: ComponentRef<TooltipComponent>;

  @HostBinding('style.top.px') @Input() top: number;
  @HostBinding('style.left.px') @Input() left: number;
}
