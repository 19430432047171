<div (click)="hide()" class="backdrop">
  <div
    (click)="$event.stopPropagation()"
    (touchstart)="touchstart($event)"
    (touchmove)="touchmove($event)"
    (touchend)="touchend($event)"
    [ngClass]="options ? options.className : ''"
    class="modal"
    #modal
  >
    <div class="d-md-none action"></div>

    <div preventTouchmove class="content">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </div>
</div>
