import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import {
  PortalFlightItineraryGroupNode,
  PortalFlightItinerarySegmentNode,
} from '@app/services/api/api.types';
import { getLongestSegment, getMixedClassesState } from '@app/utils/utils';

@Component({
  selector: 'option-itinerary',
  templateUrl: './option-itinerary.component.html',
  styleUrls: ['./option-itinerary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionItineraryComponent implements OnChanges {
  mixedClasses: boolean;
  longestSegment: PortalFlightItinerarySegmentNode;

  @Input() itinerary: PortalFlightItineraryGroupNode;

  ngOnChanges() {
    this.mixedClasses = getMixedClassesState(this.itinerary);
    this.longestSegment = getLongestSegment(this.itinerary);
  }
}
