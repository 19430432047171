import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portalCurrency',
})
export class PortalCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number, prop?: 'rounded') {
    value = value || 0;

    if (prop === 'rounded') {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
    }

    if (Number(value) === Math.round(Number(value))) {
      return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0');
    }

    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2');
  }
}
