<card class="d-flex flex-column p-4">
  <div [innerHtml]="disclaimers || ''" class="disclaimers" data-id="disclaimers-html"></div>

  <div class="mt-6">
    <checkbox
      (click)="selectReadAndAccept.emit(!readAndAccept)"
      [selected]="readAndAccept"
      [error]="readAndAcceptError"
      id="read-and-accept"
      data-id="read-and-accept"
    >
      I hereby authorize BCC and its suppliers to charge the credit card I provide for all travel
      products and services (including each booking listed in my itinerary) on behalf of me and my
      traveling party, and I agree to BCC’s
      <a
        (click)="$event.stopPropagation()"
        href="https://businessclassconsolidator.com/terms/"
        target="_blank"
        class="link text-bleu-de-france-600"
        >Terms & Conditions</a
      >. This authorization shall remain in full force and effect until terminated by myself in
      writing, such termination to be effective only upon receipt by BCC.</checkbox
    >
  </div>
</card>
