import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'repeat-cards',
  templateUrl: './repeat-cards.component.html',
  styleUrls: ['./repeat-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatCardsComponent extends FieldArrayType {}
