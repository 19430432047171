import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  Injectable,
  TemplateRef,
  Type,
} from '@angular/core';

import { ModalComponent } from '@app/ui/components/modal/modal.component';
import { TooltipComponent } from '@app/ui/components/tooltip/tooltip.component';
import { setMaxHeight } from '@app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class PopupsService {
  componentRefs: ComponentRef<TooltipComponent | ModalComponent>[] = [];

  scrollY?: number; // https://www.jayfreestone.com/writing/locking-body-scroll-ios/

  constructor(private applicationRef: ApplicationRef) {}

  show(
    component: Type<TooltipComponent | ModalComponent>,
    content: TemplateRef<unknown>,
    options?: PopupsOptions
  ) {
    const environmentInjector = this.applicationRef.injector;

    const componentRef = createComponent(component, { environmentInjector });

    Object.assign(componentRef.instance, { content, componentRef });

    if (options) {
      Object.assign(componentRef.instance, { options });
    }

    const [rootNode] = (componentRef.hostView as EmbeddedViewRef<unknown>)
      .rootNodes as HTMLElement[];

    document.body.appendChild(rootNode);

    this.applicationRef.attachView(componentRef.hostView);

    this.componentRefs?.push(componentRef);

    return componentRef;
  }

  hide(componentRef: ComponentRef<TooltipComponent | ModalComponent>) {
    const foundIndex = this.componentRefs.findIndex((ref) => ref === componentRef);

    if (foundIndex > -1) {
      const [componentRef] = this.componentRefs.splice(foundIndex, 1);

      this.unfreezeBody();

      this.applicationRef.detachView(componentRef.hostView);

      componentRef.destroy();

      const [rootNode] = (componentRef.hostView as EmbeddedViewRef<unknown>)
        .rootNodes as HTMLElement[];

      rootNode.remove();
    }
  }

  hideLast() {
    if (this.componentRefs.length) {
      this.hide(this.componentRefs[this.componentRefs.length - 1]);
    }
  }

  showTooltip(content: TemplateRef<unknown>) {
    return this.show(TooltipComponent, content) as ComponentRef<TooltipComponent>;
  }

  showModal(content: TemplateRef<unknown>, options?: PopupsOptions) {
    setMaxHeight();

    this.freezeBody();

    return this.show(ModalComponent, content, options) as ComponentRef<ModalComponent>;
  }

  freezeBody() {
    if (!this.componentRefs.length) {
      this.scrollY = window.scrollY;

      document.body.style.top = `-${this.scrollY}px`;
      document.body.classList.add('noscroll', 'p-fixed');
    }
  }

  unfreezeBody() {
    if (!this.componentRefs.length) {
      document.body.style.top = ``;
      document.body.classList.remove('noscroll', 'p-fixed');

      window.scrollTo({ top: this.scrollY });

      delete this.scrollY;
    }
  }
}

export type PopupsOptions = { className: string };
