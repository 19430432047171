import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'cfar-select',
  templateUrl: './cfar-select.component.html',
  styleUrls: ['./cfar-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfarSelectComponent {
  @Input() cancelForAnyReason?: number;
  @Input() option: PortalOrderNode;

  @Output() selectCancelForAnyReason = new EventEmitter<number>();
}
