<header class="header"></header>

<div class="d-md-none p-4 content">
  <div class="d-flex flex-column title">
    <skeleton [height]="24"></skeleton>
    <skeleton [height]="20" class="mt-2"></skeleton>
  </div>
  <skeleton [height]="288" class="mt-4"></skeleton>
  <skeleton [height]="84" class="mt-4"></skeleton>
  <skeleton [height]="540" class="mt-4"></skeleton>
  <skeleton [height]="540" class="mt-4"></skeleton>
  <skeleton [height]="540" class="mt-4"></skeleton>
</div>

<div class="d-none d-md-flex flex-column mx-auto py-md-10 px-md-0 content">
  <div class="d-flex flex-column title">
    <skeleton [height]="40"></skeleton>
    <skeleton [height]="20" class="mt-2"></skeleton>
  </div>
  <skeleton [height]="120" class="mt-8"></skeleton>
  <skeleton [height]="44" class="mt-4"></skeleton>
  <skeleton [height]="280" class="mt-4"></skeleton>
  <skeleton [height]="280" class="mt-4"></skeleton>
  <skeleton [height]="280" class="mt-4"></skeleton>
</div>
