import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'book-error-modal',
  templateUrl: './book-error-modal.component.html',
  styleUrls: ['./book-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookErrorModalComponent {
  @Output() refreshPage = new EventEmitter();
}
