import { Pipe, PipeTransform } from '@angular/core';

import { PortalFlightItinerarySegmentNode } from '@app/services/api/api.types';

@Pipe({
  name: 'daysShiftAfterLayover',
})
export class DaysShiftAfterLayoverPipe implements PipeTransform {
  transform(segments: PortalFlightItinerarySegmentNode[], index: number) {
    const prevSegment = segments[index - 1];
    const currentSegment = segments[index];

    if (prevSegment && currentSegment) {
      const prevArrivalDay = prevSegment.flightSegment.arrivalAtAirportTimeZone.split(' ')[0];
      const currentDepartureDay =
        currentSegment.flightSegment.departureAtAirportTimeZone.split(' ')[0];

      return prevSegment.layover && prevArrivalDay !== currentDepartureDay;
    }

    return false;
  }
}
