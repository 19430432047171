import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'hidden-city-tooltip',
  templateUrl: './hidden-city-tooltip.component.html',
  styleUrls: ['./hidden-city-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenCityTooltipComponent {
  @Input() option: PortalOrderNode;
}
