import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PortalOrderNode } from '@app/services/api/api.types';

@Component({
  selector: 'protection-select',
  templateUrl: './protection-select.component.html',
  styleUrls: ['./protection-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionSelectComponent {
  @Input() disruptionProtection?: number;
  @Input() option: PortalOrderNode;

  @Output() selectProtection = new EventEmitter<number>();
}
