import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnInit, OnChanges {
  steps: Step[];

  activeIndex: Step['index'];

  expanded: boolean;

  @Input() step: Steps;
  @Input() withPayAndConfirm: boolean;

  ngOnInit() {
    this.steps = (
      this.withPayAndConfirm
        ? [
            Steps.MeetYourExpert,
            Steps.PickYourFlight,
            Steps.TravelerDetails,
            Steps.OverviewAndPayment,
          ]
        : [Steps.MeetYourExpert, Steps.PickYourFlight, Steps.TravelerDetails]
    ).map((step, index) => ({ step, index }));

    this.setActiveIndex();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['step'].firstChange) {
      this.setActiveIndex();
    }
  }

  setActiveIndex() {
    this.activeIndex =
      (this.steps.find(({ step }) => step === this.step)?.index as Step['index']) ||
      this.steps.length;
  }
}

export type Step = { step: Steps; index: number };

export enum Steps {
  MeetYourExpert = 'Meet your Expert',
  PickYourFlight = 'Pick your flight',
  TravelerDetails = 'Traveler details',
  Protection = 'Protection',
  CancelForAnyReason = 'CancelForAnyReason',
  Tips = 'Tips',
  OverviewAndPayment = 'Overview & payment',
  ThankYou = 'Thank you',
}
